.container {
  padding: 2rem;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 2rem;
}

table th, table td {
  padding: 0.5rem; /* Reduce padding to make the table more compact */
  border: 1px solid var(--black-purple);
}

table th {
  background-color: var(--warm-grey); /* Change header background to Warm Grey */
  color: var(--black-purple);
  cursor: pointer;
}

/*
table tr:nth-child(even) {
  background-color: var(--off-white);
}*/

table tr:hover {
  background-color: var(--yellow);
}

input {
  padding: 0.5rem;
  width: 100%;
  border: 1px solid var(--black-purple);
  box-sizing: border-box;
  border-radius: 0; /* Remove rounded corners from text box inputs */
}

/* Style the buttons within the table */
table button {
  background-color: transparent; /* Make button background transparent */
  color: var(--black-purple);
  border: 1px solid var(--black-purple); /* Add Black Purple outline */
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: var(--border-radius);
  font-family: var(--font-family-base);
  font-size: 1rem;
}

table button:hover {
  background-color: var(--black-purple);
  color: var(--off-white);
}

