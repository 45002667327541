.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: var(--off-white);
}

.login-container {
  background-color: var(--white);
  padding: 2rem;
  border-radius: 17px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  text-align: center;
}

h1 {
  color: var(--black-purple);
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 400;
  margin-bottom: 1.5rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--black-purple);
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 400;
}

input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--warm-grey);
  border-radius: 17px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 14px;
}

button {
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 17px;
  background-color: var(--yellow);
  color: var(--black-purple);
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 1rem;
}

button:disabled {
  background-color: var(--warm-grey);
  cursor: not-allowed;
}

button:hover:enabled {
  background-color: var(--umps-red);
  color: var(--off-white);
}

.error-text {
  color: var(--umps-red);
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 400;
  margin-top: 0.5rem;
}
