@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;600&display=swap');

/* Reset styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Variables */
:root {
  --white: #FFFFFF;
  --off-white: #FFF5F0;
  --warm-grey: #E2DADC;
  --yellow: #FFDF93;
  --black-purple: #292631;
  --umps-red: #FF4176;

  --font-family-base: 'Plus Jakarta Sans', sans-serif;
  --font-size-base: 12px;
  --border-radius: 17px;
}

/* Typography */
body {
  font-family: var(--font-family-base);
  font-size: var(--font-size-base);
  color: var(--black-purple);
  background-color: var(--white);
  line-height: 1.6;
}

/* Utility classes */
.m-1 { margin: 0.25rem; }
.m-2 { margin: 0.5rem; }
.m-3 { margin: 1rem; }
.m-4 { margin: 2rem; }

.p-1 { padding: 0.25rem; }
.p-2 { padding: 0.5rem; }
.p-3 { padding: 1rem; }
.p-4 { padding: 2rem; }

/* Headers */
header {
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  background-color: var(--off-white);
  color: var(--black-purple);
  border-bottom: 1px solid var(--warm-grey);
  margin: 2rem; /* Add space around the header */
  margin-bottom: 0rem;
  border-radius: var(--border-radius);
}

.header-left, .header-center, .header-right {
  flex: 1;
  display: flex;
  align-items: center;
}

.header-left {
  justify-content: flex-start;
  vertical-align: top;
}

.header-center {
  justify-content: center;
}

.header-right {
  justify-content: flex-end;
}


.portal-logo {
  height: 50px;
  width: auto;
}

.portal-title {
  font-size: 1.5rem;
  font-weight: 400;
}

.page-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0rem;
}

.logout-button {
  background-color: var(--yellow);
  color: var(--black-purple);
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: var(--border-radius);
  font-family: var(--font-family-base);
  font-size: 1rem;
  width: 100px; /* Fixed width for the button */
  margin-top: 0rem;
}

.logout-button:hover {
  background-color: var(--umps-red);
  color: var(--off-white);
}

h1, h2, h3 {
  color: var(--black-purple);
  margin-bottom: 1rem;
  font-weight: 600;
}

button {
  background-color: var(--yellow);
  color: var(--black-purple);
  border: none;
  cursor: pointer;
  border-radius: var(--border-radius);
  font-family: var(--font-family-base);
  font-size: 1rem;
}

button:disabled {
  background-color: var(--warm-grey);
  cursor: not-allowed;
}

button:hover {
  background-color: var(--umps-red);
  color: var(--off-white);
}
